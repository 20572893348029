.header {
  text-align: center;
}

/* Sidebar styles */
.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

/* Collapsed sidebar styles */
.sidebar-collapsed .nav-link {
  text-align: center;
  padding: 0.5rem;
}

.sidebar-collapsed .nav-link i {
  margin-right: 0 !important;
  font-size: 1.25rem;
}

.sidebar-container {
  transition: width 0.3s ease;
}

.sidebar-container-collapsed {
  width: 70px !important;
}

/* New toggle button at bottom of sidebar */
.sidebar-toggle-btn {
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  z-index: 1010;
  padding: 6px 12px;
  transition: all 0.2s ease;
}

.sidebar-collapsed .sidebar-toggle-btn {
  width: 40px;
  margin: 0 auto;
}

.sidebar-toggle-btn:hover {
  background-color: #e9ecef;
  transform: translateY(-1px);
}

/* Fix dropdown positioning for collapsed sidebar */
.sidebar-collapsed .dropdown-menu {
  min-width: 180px !important;
  left: auto !important;
  right: 0 !important;
}

.sidebar .nav-link {
  color: #333;
  border-radius: 5px;
  margin-bottom: 5px;
  transition: all 0.3s;
}

.sidebar .nav-link:hover {
  background-color: #f8f9fa;
}

.sidebar .nav-link.active {
  background-color: #0d6efd;
  color: white;
}

.sidebar .dropdown-toggle::after {
  display: none;
}

.sidebar-user {
  padding: 10px;
  border-top: 1px solid #dee2e6;
  position: relative;
}

/* Make sure the sidebar scrolls properly */
.sidebar .overflow-auto {
  overflow-y: auto;
}

/* Mobile sidebar toggle button */
.sidebar-toggle {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1030;
}

/* Content area adjustments */
@media (min-width: 768px) {
  .content-with-sidebar {
    margin-left: 250px;
    transition: margin-left 0.3s ease, width 0.3s ease;
    width: auto;
  }
  
  .sidebar-collapsed-content {
    margin-left: 70px !important;
  }
}

/* Mobile specific styles */
@media (max-width: 767px) {
  .content-with-sidebar {
    margin-left: 0 !important;
    width: 100% !important;
  }
  
  /* Ensure the offcanvas sidebar is wide enough */
  .offcanvas {
    max-width: 80%;
    min-width: 250px;
  }
  
  /* Fix any potential margin issues */
  main {
    margin-left: 0 !important;
  }
}

/* Offcanvas sidebar for mobile */
.offcanvas {
  max-width: 80%;
}

/* Dropdown menu in sidebar */
.sidebar .dropdown-menu {
  position: absolute !important;
  width: 200px !important;
  margin-top: 0;
  margin-bottom: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  z-index: 1050;
}

.sidebar .dropdown-item {
  padding: 8px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar .dropdown-item:hover {
  background-color: #e9ecef;
}

/* Verification alert */
.verification-alert-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1100;
  width: 100%;
}

/* Updated styles for mobile view with verification alert */
@media (max-width: 767px) {
  .verification-alert-container .alert {
    padding-left: 50px; /* Make space for the sidebar toggle button */
  }
  
  .sidebar-toggle {
    z-index: 1200; /* Ensure toggle is above the alert */
  }
  
  .has-verification-alert {
    padding-top: 60px; /* Increased padding to accommodate the alert */
  }
}

.has-verification-alert {
  padding-top: 50px;
}

.sidebar .flex-grow-0 {
  flex-shrink: 0;
}

.sidebar .flex-grow-1 {
  overflow-y: auto;
  min-height: 0;
}

.sidebar .mt-auto {
  flex-shrink: 0;
  margin-top: auto !important;
}

/* Dashboard Styles */
.dashboard-container {
  padding: 20px;
}

.dashboard-last-updated {
  font-size: 0.85rem;
  color: #6c757d;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 4px 10px;
  border-radius: 4px;
  display: inline-block;
}

.dashboard-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  height: 100%;
}

.dashboard-card h3 {
  color: #333;
  font-size: 1.2rem;
  margin-bottom: 15px;
  font-weight: 600;
}

.dashboard-card .loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.recharts-wrapper {
  margin: 0 auto;
}

/* Custom tooltip styles */
.custom-tooltip {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 250px;
}

.custom-tooltip .label {
  font-weight: bold;
  margin-bottom: 5px;
  word-wrap: break-word;
  white-space: normal;
  max-width: 230px;
}

.custom-tooltip p {
  margin: 0;
  color: #333;
}

/* Improved X-axis label styles */
.recharts-xAxis .recharts-cartesian-axis-tick text {
  font-size: 11px;
}

.recharts-xAxis .recharts-cartesian-axis-tick-value {
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Dashboard Responsive Styles */
@media (max-width: 768px) {
  .dashboard-container {
    padding: 15px 10px;
  }
  
  .dashboard-card {
    padding: 15px;
    margin-bottom: 15px;
  }
  
  .dashboard-card h3 {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .recharts-wrapper {
    font-size: 12px;
  }
  
  .recharts-legend-wrapper {
    font-size: 10px;
  }
}

/* Profile Page Styles */
.avatar-circle {
  width: 100px;
  height: 100px;
  background-color: #007bff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2.5rem;
  font-weight: 500;
}

.avatar-initials {
  line-height: 1;
}

.business-logo {
  width: 100px;
  height: 100px;
  background-color: #28a745;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.profile-info {
  margin-top: 1.5rem;
  border-top: 1px solid #eee;
  padding-top: 1.5rem;
}

.profile-info-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px dashed #eee;
}

.profile-info-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.profile-info-label {
  color: #6c757d;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.profile-info-value {
  font-size: 1.1rem;
  font-weight: 500;
}

/* Change Password Modal Styles */
.modal-header.bg-primary {
  border-bottom: none;
}

.modal-header .modal-title {
  font-size: 1.25rem;
  font-weight: 500;
}

.modal-header .btn-close {
  color: white;
  opacity: 0.8;
}

.modal-header .btn-close:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .avatar-circle, .business-logo {
    width: 80px;
    height: 80px;
    font-size: 2rem;
  }
  
  .profile-info-value {
    font-size: 1rem;
  }
}
